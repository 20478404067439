import React, { useEffect, useState } from "react";
import api from "../../shared/utils/api";
import FormGroupInput from "../../shared/components/FormGroupInput";
import FormGroupProvinceDropdownInput from "../../shared/components/FormGroupProvinceDropdownInput";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import PostalCodeInput from "../../shared/components/PostalCodeInput";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal"; // Import Modal from react-bootstrap

export default function RequestHomeInspection() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [validated, setValidated] = useState(false);
  const [homeOwnerEmail, setHomeOwnerEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");

  const [homeOwnerName, setHomeOwnerName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [homeOwnerPhoneNumber, setHomeOwnerPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("BC");
  const [postalCode, setPostalCode] = useState("");
  const [territories, setTerritories] = useState([]);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState(1);
  const [agency, setAgency] = useState("");
  const [propertyType, setPropertyType] = useState("");

  // State for managing the Terms modal visibility
  const [showTermsModal, setShowTermsModal] = useState(false);

  const requestInspection = (homeOwnerEmail) => {
    // TODO: input sanitization
    api
      .post("/home-inspections", {
        //TODO: Audit ID will come from what type of Audit the user is requesting
        // since we don't have that we are going to hardcode it to 1
        auditID: 1,
        homeInspectionStatus: "Requested",
        homeOwnerEmailAddress: homeOwnerEmail,
        homeOwnerPhoneNumber:
          homeOwnerPhoneNumber === "" ? null : "+1" + homeOwnerPhoneNumber,
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        territoryId: +selectedTerritoryId,
      })
      .then(
        (response) => {
          setAccessCode(response.homeInspectionAccessCode);
          setActiveIndex(1);
          toast.success("Your home assessment request was been successful!", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const agencyParam = params.get("agency");

    if (agencyParam) {
      setAgency(agencyParam);
      const matchingTerritory = territories.find(
        (territory) => territory.name === agencyParam,
      );
      if (matchingTerritory) {
        setSelectedTerritoryId(matchingTerritory.id);
      }
    }
  }, [territories]);

  const handleTerritoryChange = (e) => {
    setSelectedTerritoryId(e.target.value);
  };

  useEffect(() => {
    const requestUrl = "/territories-for-public";
    api.get(requestUrl).then(
      (response) => {
        setTerritories(response);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    // Add email matching validation
    if (homeOwnerEmail !== confirmEmail) {
      toast.error("Email addresses must match");
      e.stopPropagation();
      setValidated(true);
      return;
    }

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return; // Return if validation failed
    }

    setValidated(true);

    requestInspection(homeOwnerEmail);
  };

  const handleViewHomeInspection = (e) => {
    e.preventDefault();
    window.location.href = "/view-home-inspection";
  };

  const handleRequestAnotherHomeInspection = (e) => {
    e.preventDefault();
    setValidated(false);
    setHomeOwnerEmail("");
    setConfirmEmail("");
    setHomeOwnerPhoneNumber("");
    setHomeOwnerName("");
    setAddress("");
    setCity("");
    setProvince("");
    setPostalCode("");
    setPropertyType("");
    setActiveIndex(0);
  };

  // Handlers to open and close the Terms modal
  const handleOpenTermsModal = () => setShowTermsModal(true);
  const handleCloseTermsModal = () => setShowTermsModal(false);

  return (
    <div className="md:w-[75%]">
      <h2>Request A Home Assessment{agency ? " in " + agency : ""}.</h2>
      <p>
        Taking action to FireSmart your property will decrease the risk of
        wildfire damaging your home.
      </p>
      <p>
        During an assessment, a Wildfire Mitigation Specialist will provide a
        detailed work plan with recommendations specific to your property. For
        more information, please contact your local{" "}
        <a
          href="https://firesmartbc.ca/firesmart-coordinators/"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          FireSmart Coordinator
        </a>{" "}
        in your community or{" "}
        <a className="underline" href="mailto:wmp.admin@FireSmartBC.ca">
          wmp.admin@FireSmartBC.ca
        </a>
      </p>
      <p>
        Please fill out your information below to request a home assessment:
      </p>

      {activeIndex === 0 ? (
        <HomeInspectionRequestForm
          homeOwnerEmail={homeOwnerEmail}
          setHomeOwnerEmail={setHomeOwnerEmail}
          confirmEmail={confirmEmail}
          setConfirmEmail={setConfirmEmail}
          homeOwnerName={homeOwnerName}
          setHomeOwnerName={setHomeOwnerName}
          homeOwnerPhoneNumber={homeOwnerPhoneNumber}
          setHomeOwnerPhoneNumber={setHomeOwnerPhoneNumber}
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          province={province}
          setProvince={setProvince}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          handleSubmit={handleSubmit}
          validated={validated}
          handleTerritoryChange={handleTerritoryChange}
          selectedTerritoryId={selectedTerritoryId}
          territories={territories}
          handleOpenTermsModal={handleOpenTermsModal} // Pass the handler to open modal
        />
      ) : (
        <HomeInspectionRequestReport
          homeOwnerEmail={homeOwnerEmail}
          accessCode={accessCode}
          handleViewHomeInspection={handleViewHomeInspection}
          handleRequestAnotherHomeInspection={
            handleRequestAnotherHomeInspection
          }
        />
      )}

      {/* Terms and Conditions Modal */}
      <Modal
        className="terms-modal"
        show={showTermsModal}
        onHide={handleCloseTermsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            RIGHT OF ENTRY/HOLD HARMLESS AGREEMENT As a FireSmart Home Partners
            program participant, I understand that I am required to permit and
            participate in a wildfire property assessment on my property with a
            FireSmart Home Partners Wildfire Mitigation Specialist. Based upon
            the results of the property assessment, the FireSmart Home Partners
            Wildfire Mitigation Specialist will work with me to take steps that
            may reduce the risk of wildfire damage to my property. I acknowledge
            and agree to the following:
          </p>
          <p>
            RIGHT OF ENTRY: I certify that I am the owner or the owner’s
            authorized agent of the above-described property, and that I have
            the legal authority to enter into this Agreement. I grant freely and
            without coercion the right of access and entry to my property to
            FireSmart Canada (FireSmart), its agents, partner agencies
            organizations in right of the province of British Columbia (the
            “Province”) and authorized contractors at a mutually agreed upon
            date and time to assess my property for the risk of wildfire damage,
            to develop a customized wildfire mitigation report designed to
            reduce the risk of wildfire damage, to potentially mark vegetation
            for removal with paint and to make follow up visits to my property
            to make sure I am fully prepared for future wildfires.
          </p>
          <p>
            RELEASE AND HOLD HARMLESS: I understand that this Agreement/Permit
            is not an obligation upon FireSmart Home Partners to perform any
            work my property. I hereby release, waive, discharge, and hold
            harmless FireSmart, the Canadian Interagency Forest Fire Centre, the
            Province and the participating local governments, its elected and
            appointed officials, and its employees, agents and representatives,
            and Wildfire Mitigation Specialists from all claims, liabilities,
            damages, losses, or expenses on account of damage to property,
            bodily injury, or death resulting from my participation in the
            FireSmart Home Partners program. By participating in the FireSmart
            Home Partners program and by executing this Agreement, it is my
            express intention and I hereby waive any and all claims which I
            might have to bring in any form, whether legal or equitable, arising
            out of my participation in the FireSmart Home Partners program and
            any incidence relating to, but not limited to, the performance of
            the property assessment, inspection and educational work by any
            released party herein.
          </p>
          <p>
            DISCLOSURE OF PROPERTY OWNER INFORMATION: I understand that
            FireSmart Home Partners will collect data regarding my property in
            order to prepare a customized fire mitigation plan. With the
            exception of specific provincial government or local government or
            First Nations government responsible for performing these
            assessments, FireSmart Home Partners Program will not share this
            data with third parties without the expressed written consent of the
            property owner. FireSmart Home Partners Program will not disclosure
            to third parties, except as required by law, any personal
            information they may collect in connection with my participation in
            the program. FireSmart Home Partners Program will share program
            information with program funders as part of normal program
            reporting. I further acknowledge that FireSmart Home Partners
            Program will disclose the name, address and status of program
            participants to their local fire protection district.
          </p>
          <p>
            DISCLAIMER: I have read and understand the following disclaimer:
            While the purpose of the FireSmart Home Partners program property
            assessment is to reduce the risk of fire damage to your home or
            property, following the requirements in the Wildfire Mitigation
            Report does not guarantee prevention of such damage. Wildfire
            mitigation work around your home can give firefighters the best
            chance to defend and protect your property from wildfires, and can
            also substantially increase your safety and reduce the risk to life
            and property. However, wildfire is unpredictable and can be
            impossible to stop or control, regardless of what mitigation efforts
            you have taken. The FireSmart Home Partners program makes no
            warranties, guarantees or representations of any kind with
            respective to the effectiveness of any mitigation efforts you
            undertake in connection with your participation in the FireSmart
            Home Partners program.
          </p>
          <p>
            Note: Property Owners who join the Home Partners program may be
            asked to complete work in the Priority Zones that surround their
            home, and fire-resistant construction retrofits to their home in
            order to qualify for a Home Partners certificate. These mitigation
            actions are strictly voluntary but may be required in order to earn
            a program certificate. Property Owners must seek and attain all
            required permits and/or HOA/Strata approval before removing trees
            from their property and/or initiating new construction or
            alterations to their homes. Preparing for wildfires is an ongoing,
            long-term effort that requires maintenance and upkeep and does not
            guarantee home survival. More information on FireSmart Canada
            guidelines is available at:{" "}
            <a
              href="https://www.firesmartcanada.ca"
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.firesmartcanada.ca
            </a>
            .
          </p>
          <p>
            I/we (name of property owner(s) or legal representative as entered
            above) agree to accept the terms as outlined in this application and
            the FireSmart Home Partners program liability waiver in order to be
            eligible for the program. I/we understand that we can withdraw from
            the program at any time through written notification submitted to
            the program coordinator. Withdrawal from the program will result in
            my/our ineligibility to receive a FireSmart Home Partners program
            certificate.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-black btn-standard"
            onClick={handleCloseTermsModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

// Rendered when activeIndex is 0
function HomeInspectionRequestForm({
  homeOwnerEmail,
  setHomeOwnerEmail,
  confirmEmail,
  setConfirmEmail,
  setHomeOwnerName,
  setHomeOwnerPhoneNumber,
  setAddress,
  setCity,
  province,
  postalCode,
  setProvince,
  setPostalCode,
  handleSubmit,
  validated,
  handleTerritoryChange,
  selectedTerritoryId,
  territories,
  setPropertyType,
  handleOpenTermsModal,
}) {
  return (
    <div className="col">
      <div className="form-container">
        {/* <h4>Request Home Assessment Form</h4> */}
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="pt-3"
        >
          <Row className="flex flex-wrap">
            <Form.Group
              as={Col}
              controlId="territory"
              className="form-group lg:w-1/2 mb-3"
            >
              {selectedTerritoryId != 1 &&
              selectedTerritoryId &&
              territories.some(
                (territory) => territory.id === selectedTerritoryId,
              ) ? (
                <input
                  type="hidden"
                  name="territory"
                  value={selectedTerritoryId}
                />
              ) : (
                <>
                  <Form.Label className="font-bold">
                    What jurisdiction do you live in?
                  </Form.Label>
                  <Form.Select
                    className="form-select"
                    onChange={handleTerritoryChange}
                    value={selectedTerritoryId}
                  >
                    <option value="" disabled>
                      Please select
                    </option>
                    {territories.map((territory) => (
                      <option key={territory.id} value={territory.id}>
                        {territory.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Must select one of the territories listed.
                  </Form.Control.Feedback>
                </>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3 flex flex-wrap">
            <FormGroupInput
              controlId="homeOwnerEmail"
              label="Email Address"
              required
              type="email"
              invalidFeedback="Must provide a valid email."
              onChange={(e) => setHomeOwnerEmail(e.target.value)}
              className="lg:w-1/2 !flex-none mb-3"
            />
            <FormGroupInput
              controlId="confirmHomeOwnerEmail"
              label="Confirm Email Address"
              required
              type="email"
              value={confirmEmail}
              invalidFeedback="Email addresses must match."
              onChange={(e) => setConfirmEmail(e.target.value)}
              onPaste={(e) => {
                e.preventDefault();
                toast.warn("Please type the email address again");
              }}
              isInvalid={confirmEmail !== "" && confirmEmail !== homeOwnerEmail}
              className="lg:w-1/2 !flex-none mb-3"
            />
            <FormGroupInput
              controlId="homeOwnerName"
              label="Name"
              required
              type="text"
              invalidFeedback="Must provide a name."
              onChange={(e) => setHomeOwnerName(e.target.value)}
              className="lg:w-1/2 !flex-none mb-3"
            />

            <FormGroupInput
              controlId="homeOwnerPhoneNumber"
              label="Phone Number (Optional)"
              type="tel"
              invalidFeedback="Must provide a phone number in the format 123-456-7890 or 1234567890."
              onChange={(e) => setHomeOwnerPhoneNumber(e.target.value)}
              pattern="(\d{3}-?\d{3}-?\d{4})|(\d{10})"
              className="lg:w-1/2 !flex-none mb-3"
            />
            <FormGroupInput
              controlId="address"
              label="Address"
              required
              type="text"
              invalidFeedback="Must provide an address with allowed special characters .:-_', and allowed spaces."
              onChange={(e) => setAddress(e.target.value)}
              pattern="^[a-zA-Z0-9 .:,-_']+$"
              className="lg:w-1/2 mb-3"
            />
            <FormGroupInput
              controlId="city"
              label="City"
              required
              type="text"
              invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
              onChange={(e) => setCity(e.target.value)}
              pattern="^[a-zA-Z0-9 .:,-_']+$"
              className="lg:w-1/2 !flex-none mb-3"
            />
            <FormGroupProvinceDropdownInput
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              required
              placeHolderRequired
              className="lg:w-1/2 !flex-none mb-3"
            />
            <div class="col lg:w-1/2 !flex-none mb-3">
              <PostalCodeInput
                controlId="postalCode"
                label="Postal Code"
                required
                type="text"
                invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
                value={postalCode}
                onChange={(e) => setPostalCode(e)}
                pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
              />
            </div>
            <Form.Group
              controlId="propertyType"
              className="lg:w-1/2 !flex-none mb-3"
              onChange={(e) => setPropertyType(e.target.value)}
            >
              <Form.Label className="font-bold">Type of Property</Form.Label>
              <Form.Select required className="form-select">
                <option value="">Please select</option>
                <option value="single_family">Detached Home</option>
                <option value="multi_family">Multi-Family Home</option>
                <option value="townhouse">Duplex</option>
                <option value="townhouse">Multiplex</option>
                <option value="apartment">Apartment/Condo</option>
                <option value="mobile_home">Mobile Home</option>
                <option value="other">Other</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select a property type.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          {/* Checkbox for Terms and Conditions */}
          <Row className="mb-3">
            <Form.Group
              className="flex items-start"
              controlId="termsAndConditions"
            >
              <Form.Check
                required
                type="checkbox"
                feedback="You must agree to the terms and conditions before submitting."
                feedbackType="invalid"
                className="-mt-1 mr-2 "
              />
              <span className="flex-1">
                I/we agree to be bound by the{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={handleOpenTermsModal}
                >
                  following terms
                </span>
                .
              </span>
            </Form.Group>
          </Row>
          {/* Checkbox for Property Owner */}
          <Row className="mb-3">
            <Form.Group
              className="flex items-start"
              controlId="termsAndConditions"
            >
              <Form.Check
                required
                type="checkbox"
                feedback="You must confirm that you are the property owner or have received permission from the owner."
                feedbackType="invalid"
                className="-mt-1 mr-2 "
              />
              <span className="flex-1">
                I confirm that I am the property owner or legal representative,
                or have received expressed permission from them to have this
                assessment conducted.
              </span>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="form-group">
              <Button className="btn-standard margin-right-10" type="submit">
                Request Home Assessment
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>
  );
}

// Rendered when activeIndex is 1
function HomeInspectionRequestReport({
  homeOwnerEmail,
  accessCode,
  handleViewHomeInspection,
  handleRequestAnotherHomeInspection,
}) {
  return (
    <div className="col">
      <div className="form-container">
        <h4>Home Assessment Request Report</h4>
        <p>Your home assessment request has been submitted!</p>
        <p>
          An email has been sent to the email address {homeOwnerEmail} with the
          access code {accessCode}.
        </p>
        <p>
          Use this access code with your email address to view your home
          assessment.
        </p>
        <Button
          className="btn-standard margin-right-10"
          type="submit"
          onClick={handleViewHomeInspection}
        >
          View Home Assessment
        </Button>
        <Button
          className="btn-standard margin-right-10"
          type="submit"
          onClick={handleRequestAnotherHomeInspection}
        >
          Request Another Home Assessment
        </Button>
      </div>
    </div>
  );
}

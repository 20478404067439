import "react-confirm-alert/src/react-confirm-alert.css";

import CheckBox from "../../../shared/components/CheckBox";
import { ERROR_MESSAGE_DURATION } from "../../../shared/Constants";
import HomeInspectionFileUpload from "../../HomeInspectionFileUpload";
import Image from "./Image";
import ImageContainer from "./ImageContainer";
import MultiSelectOptions from "./MultiSelectOptions";
import React from "react";
import api from "../../../shared/utils/api";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { getUserRole, isUserAdmin } from "../../../shared/utils/authToken";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { PiArrowBendRightDownFill } from "react-icons/pi";
import { AiOutlineSave } from "react-icons/ai";

import _ from "lodash";
import Annotator from "../../ImageAnnotation/Annotator";
import { X } from "lucide-react";

function MediaPreview({ media }) {
  if (media === null) {
    return null;
  } else {
    return (
      <>
        <label className="form-label font-weight-bold">Preview</label>
        <img className="audit-image-full" src={media} alt="Assessment Image" />
      </>
    );
  }
}

function Question({
  questionID,
  questionContent,
  questionAnswers,
  comment,
  homeOwnerComment,
  supervisorComment,
  supervisorCommentStatus,
  defaultComment,
  images,
  requiresActionValue,
  setCommentCallback,
  homeInspectionCommentID,
  setSupervisorCommentCallback,
  setQuestionOptionsCallback,
  setRequiresActionCallback,
  removeImageCallback,
  auditID,
  homeInspectionID,
  moduleTitle,
  hexCode,
  updateStatusCallback,
  saveInspection,
}) {
  const [selectedOptions, setSelectedOptions] = useState(
    questionAnswers
      ?.filter((a) => a.selected)
      .map((a) => a.auditQuestionOptionID),
  );
  const [canEditSupervisorComment, setCanEditSupervisorComment] = useState(
    isUserAdmin() || getUserRole() === 3,
  );
  const [requiresAction, setRequiresAction] = useState(requiresActionValue);
  const [questionComment, setQuestionComment] = useState(
    comment ? comment.replace(/<br\s*\/?>/g, "\n\n") : "",
  );
  const [getSupervisorComment, setSupervisorComment] =
    useState(supervisorComment);
  const [getSupervisorCommentStatus, setSupervisorCommentStatus] = useState(
    supervisorCommentStatus,
  );
  const [useDefaultComment, setUseDefaultComment] = useState(
    questionComment === defaultComment?.replace(/<br\s*\/?>/g, "\n\n"),
  );
  const [imageAttachments, setImageAttachments] = useState(images || []);
  const [previewImageData, setPreviewImageData] = useState(null);
  const [file, setFile] = useState(null);

  const [imageFiles, setImageFiles] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [annotatedImages, setAnnotatedImages] = useState([]);

  const handleDeleteImage = (indexToDelete) => {
    setAnnotatedImages(
      annotatedImages.filter((_, index) => index !== indexToDelete),
    );
  };

  const handleUseDefaultCommentChange = (e) => {
    const isChecked = e.target.checked;
    if (
      !useDefaultComment &&
      questionComment !== defaultComment &&
      questionComment
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confirm-dialog">
              <h3>Reset Comment</h3>
              <p>
                Switching to the default comment will erase your current input.
                Are you sure?
              </p>
              <button
                className="btn btn-standard float-right"
                onClick={() => {
                  const formattedDefault = defaultComment?.replace(
                    /<br\s*\/?>/g,
                    "\n\n",
                  );
                  setQuestionComment(formattedDefault);
                  setCommentCallback(questionID, defaultComment);
                  setUseDefaultComment(isChecked);
                  onClose();
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-standard secondary margin-right-10 float-right"
                onClick={onClose}
              >
                No
              </button>
            </div>
          );
        },
      });
    } else if (useDefaultComment && questionComment === defaultComment) {
      setQuestionComment("");
      setCommentCallback(questionID, "");
      setUseDefaultComment(isChecked);
    } else if (!useDefaultComment && questionComment === "") {
      const formattedDefault = defaultComment?.replace(/<br\s*\/?>/g, "\n\n");
      setQuestionComment(formattedDefault);
      setCommentCallback(questionID, defaultComment);
      setUseDefaultComment(isChecked);
    }
  };

  function groupByHeaderAdvanced(xs, key) {
    if (!xs) return [];
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.values.header === v);

      if (el && v !== "") {
        el.values.options.push(x);
      } else {
        rv.push({ key: rv.length, values: { header: v, options: [x] } });
      }

      return rv;
    }, []);
  }

  const groupedAnswers = _.sortBy(
    groupByHeaderAdvanced(questionAnswers, "header"),
    (a) => a.values.sortOrder,
  );

  const handleOptionChange = (answerID) => {
    // We are UNSELECTING the answer
    if (selectedOptions.includes(answerID)) {
      setSelectedOptions(
        selectedOptions.filter((a) => parseInt(a) !== answerID),
      );
      setQuestionOptionsCallback(questionID, [answerID]);
      return;
    }

    // We are SELECTING the answer
    if (!selectedOptions.includes(answerID)) {
      // We need to check if this answer has a header
      var selectedAnswer = questionAnswers.find(
        (a) => a.auditQuestionOptionID === parseInt(answerID),
      );

      if (selectedAnswer.header === "") {
        // Unselect all other answers and only select this one
        setSelectedOptions([parseInt(answerID)]);
        setQuestionOptionsCallback(questionID, [answerID]);
        return;
      }

      if (selectedAnswer.header !== "") {
        // Unselect all other answers from other headers
        // get all answers from the same header
        var options = groupedAnswers.find(
          (group) => group.values.header === selectedAnswer.header,
        ).values.options;
        var answerIDs = options.map((a) => a.auditQuestionOptionID);
        answerIDs.filter(
          (a) => selectedOptions.includes(a) || a === parseInt(answerID),
        );
        setSelectedOptions(
          answerIDs.filter(
            (a) => selectedOptions.includes(a) || a === parseInt(answerID),
          ),
        );
        setQuestionOptionsCallback(
          questionID,
          answerIDs.filter(
            (a) => selectedOptions.includes(a) || a === parseInt(answerID),
          ),
        );
      }
    }
  };

  const handleCommentChange = (e) => {
    if (useDefaultComment) setUseDefaultComment(false);
    setQuestionComment(e.target.value);
    setCommentCallback(questionID, e.target.value.replace(/\n\n/g, "<br>"));
  };

  const handleRequiresActionChange = (e) => {
    var newVal = !requiresAction;
    setRequiresActionCallback(questionID, newVal);
    setRequiresAction(newVal);
  };

  const removeImage = (imageID) => {
    // TODO: It would probably be nice to prompt the user if they are sure they want to delete the image
    setImageAttachments(
      imageAttachments.filter((image) => image.imageID !== imageID),
    );
    removeImageCallback(questionID, imageID);
  };

  const addImage = (image) => {
    setImageAttachments([...imageAttachments, image]);
  };

  const handleFileChange = (event) => {
    // TODO: didn't have time to support multiple files, but should work with some tweaks
    // example: https://jsfiddle.net/41go76g4/

    if (event.target.files[0].size > 100000000) {
      toast.error("File size must be less than 100MB", {
        autoClose: ERROR_MESSAGE_DURATION,
      });
      setFile(null);
    }

    if (!event.target.files[0].type.match("image/*")) {
      toast.error("File must be an image", {
        autoClose: ERROR_MESSAGE_DURATION,
      });
      setFile(null);
    }

    var reader = new FileReader();

    // Closure to capture the file information.
    reader.onload = (function () {
      return function (e) {
        setPreviewImageData(e.target.result);
      };
    })(file);

    // Read in the image file as a data URL.
    reader.readAsDataURL(event.target.files[0]);

    setFile(event.target.files[0]);
  };

  const handleFileSubmit = (file) => {
    // event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("auditID", auditID);
    formData.append("auditQuestionID", questionID);
    formData.append("homeInspectionID", homeInspectionID);

    api.postFormData("/upload-home-inspection-image", formData).then(
      (response) => {
        imageUploadSuccess(response);
        toast.success("File uploaded successfully", { autoClose: 2000 });
        setFile(null);
      },
      (error) => {
        toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
      },
    );
  };

  const handleFileCancel = (e) => {
    setFile(null);
    setPreviewImageData(null);
    //get parent form and reset it
    var form = e.target.form;
    form.reset();
  };

  const imageUploadSuccess = async (response) => {
    addImage({
      imageID: response.homeInspectionImageID,
      imageURL: response.presignedURL,
    });
    setPreviewImageData(null);
  };

  const handleSupervisorCommentChange = (e) => {
    setSupervisorComment(e.target.value);
    setSupervisorCommentCallback(questionID, e.target.value);
    if (
      getSupervisorCommentStatus &&
      getSupervisorCommentStatus !== "Resolved"
    ) {
      setSupervisorCommentStatus("Unresolved");
    }
  };

  const updateSupervisorCommentStatus = (id) => {
    api
      .post(`/home-inspection-comments/update-supervisor-comment/${id}`, {})
      .then(
        (response) => {
          const newStatus =
            getSupervisorCommentStatus === "UnResolved"
              ? "Resolved"
              : "UnResolved";
          setSupervisorCommentStatus(newStatus);
          updateStatusCallback(questionID, newStatus);
          toast.success("Comment status updated successfully", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error("Error saving comment", error, {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
  };

  useEffect(() => {
    updateStatusCallback(questionID, getSupervisorCommentStatus);
  }, [getSupervisorCommentStatus]);
  return (
    <div id={`question-${questionID}`}>
      {getSupervisorCommentStatus === "UnResolved" && (
        <div className="text-orange-600 text-base mb-2 mt-4">
          This question has unresolved feedback{" "}
          <PiArrowBendRightDownFill className="h-4 w-4 inline-block mr-2" />
        </div>
      )}
      <div
        className="audit-question-container border-border border rounded-md p-4 mb-4 shadow-sm"
        key={questionID}
      >
        {moduleTitle && hexCode && (
          <span
            className="inline-block rounded-md mr-2 text-sm font-bold px-2.5 py-[0.125rem] pt-1 mb-3"
            style={{ backgroundColor: `#${hexCode}` }}
          >
            {moduleTitle}
          </span>
        )}
        <h4 className="flex flex-row gap-2 flex-nowrap">
          <AiFillQuestionCircle className="h-5 w-5 inline-block flex-shrink-0 text-black  rounded-full overflow-hidden " />
          <span className="inline-block">{questionContent}</span>
        </h4>
        <div className="row gy-2 gx-1">
          <div className="audit-options-container flex col-gap-20 mb-2 gap-y-4">
            {groupedAnswers.map((group, index) => (
              <MultiSelectOptions
                questionID={questionID}
                header={group.values.header}
                options={group.values.options}
                selectedOptions={selectedOptions}
                updateCallback={handleOptionChange}
                //className="!bg-gray-100 border-border border-[1px]"
                // index={index}
                className={`border-border border-[1px] ${
                  index === 0
                    ? "!border-green-400"
                    : index === 1
                      ? "!border-red-300"
                      : index === 2
                        ? "!border-border"
                        : ""
                }`}
              />
            ))}
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center">
              <label className="form-label font-weight-bold mb-1">
                Comment
              </label>
              {defaultComment && (
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      name={"useDefaultComment" + questionID}
                      className="form-check-input"
                      type="checkbox"
                      checked={useDefaultComment}
                      onChange={handleUseDefaultCommentChange}
                    />
                    Use default comment
                  </label>
                </div>
              )}
            </div>
            <textarea
              className="form-control mt-2 mb-2"
              rows="3"
              value={questionComment}
              onChange={handleCommentChange}
            ></textarea>
          </div>
          <div className="form-group mb-2">
            <CheckBox
              label="Requires Action"
              bold
              checked={requiresAction}
              onChange={handleRequiresActionChange}
            />
          </div>
          {homeOwnerComment && (
            <div className="form-group">
              <label for="comment" className="form-label font-weight-bold">
                Home Owner Comment
              </label>
              <textarea
                className="form-control"
                rows="3"
                value={homeOwnerComment}
                disabled
              ></textarea>
            </div>
          )}
          {/* <HomeInspectionFileUpload
            handleFileCancel={handleFileCancel}
            handleFileChange={handleFileChange}
            handleFileSubmit={handleFileSubmit}
            file={file}
          /> */}
          <label className="font-bold">Upload Images</label>
          <div className="mt-2 mb-3 flex gap-3">
            <Annotator
              imageFiles={imageFiles}
              setImageFiles={setImageFiles}
              imagesPreview={imagesPreview}
              setImagesPreview={setImagesPreview}
              setAnnotatedImages={setAnnotatedImages}
              handleFileSubmit={handleFileSubmit}
            />
            {/* {annotatedImages?.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={image}
                  className="w-20 h-20 object-cover cursor-pointer aspect-square rounded"
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteImage(index);
                  }}
                  className="z-10 absolute -right-2 -top-2  w-6 h-6 rounded-full flex items-center justify-center bg-gray-200 hover:bg-gray-300 text-black"
                >
                  <X size={14} />
                </button>
              </div>
            ))} */}
          </div>
          <MediaPreview media={previewImageData} />
          <ImageContainer>
            {imageAttachments.map((image) => (
              //TODO: Maybe if the audit is completed it renders full images instead of the previews?
              <Image
                key={image.imageID}
                imageURL={image.imageURL}
                removeImageCallback={() => removeImage(image.imageID)}
              />
            ))}
          </ImageContainer>

          <div className="form-group">
            <label
              htmlFor="supervisorComment"
              className="form-label font-weight-bold"
            >
              Supervisor Comment
            </label>
            <textarea
              className="form-control"
              rows="3"
              value={getSupervisorComment}
              disabled={canEditSupervisorComment !== true}
              onChange={handleSupervisorCommentChange}
            ></textarea>
          </div>
          <div className="form-group">
            {canEditSupervisorComment && getSupervisorCommentStatus === "" && (
              <button
                className="btn btn-standard btn-outline mt-1"
                onClick={() => saveInspection()}
              >
                <AiOutlineSave className="mr-1.5 inline-block" /> Save Comment
              </button>
            )}
            {getSupervisorCommentStatus &&
              getSupervisorCommentStatus === "UnResolved" && (
                <>
                  <button
                    className="btn btn-standard btn-outline w-auto"
                    onClick={() =>
                      updateSupervisorCommentStatus(homeInspectionCommentID)
                    }
                  >
                    <IoIosCheckmarkCircleOutline className="h-5 w-5 inline-block mr-2 " />
                    Feedback Addressed
                  </button>
                </>
              )}

            {getSupervisorCommentStatus &&
              getSupervisorCommentStatus === "Resolved" && (
                <>
                  <button
                    className="btn btn-standard btn-outline w-auto"
                    onClick={() =>
                      updateSupervisorCommentStatus(homeInspectionCommentID)
                    }
                  >
                    <AiOutlineExclamationCircle className="h-5 w-5 inline-block mr-2 " />
                    Mark as Unresolved
                  </button>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Question;

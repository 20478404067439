import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "./App.css";
import Root from "../components/Root";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import {
  getStoredAuthToken,
  removeStoredAuthToken,
  storeAuthToken,
} from "../shared/utils/authToken";
import { useState } from "react";
import history from "../shared/browserHistory";
import { ToastContainer } from "react-toastify";

//Login
import Login from "../components/Login";
import SignUp from "../components/SignUp";
import ForgotPassword from "../components/ForgotPassword";

//Courses
import CourseView from "../components/Courses/CourseView";
import CourseEdit from "../components/Courses/CourseEdit";
import AddCourse from "../components/Courses/AddCourse";
import Courses from "../components/Courses/Courses";

//Pages
import CoursePage from "../components/Courses/Pages/CoursePage";
import AddCoursePage from "../components/Courses/Pages/AddCoursePage";
import ModulePreview from "../components/Courses/Modules/ModulePreview";

//Modules
import CourseModule from "../components/Courses/Modules/CourseModule";
import AddCourseModule from "../components/Courses/Modules/AddCourseModule";

//Quizzes
import Quizzes from "../components/Quizzes/Quizzes";
import Quiz from "../components/Quizzes/Quiz";
import AddQuiz from "../components/Quizzes/AddQuiz";
import QuizQuestion from "../components/Quizzes/Questions/QuizQuestion";
import AddQuizQuestion from "../components/Quizzes/Questions/AddQuizQuestion";
import QuizAnswer from "../components/Quizzes/Questions/QuizAnswer";
import AddQuizAnswer from "../components/Quizzes/Questions/AddQuizAnswer";
import QuizPreview from "../components/Quizzes/QuizPreview";

import ProtectedRoute from "../shared/utils/ProtectedRoute";

//Users
import Users from "../components/Users";
import User from "../components/User";
import AddUser from "../components/AddUser";

// Home Inspections
import HomeInspectionRequest from "../components/HomeInspections/HomeInspectionRequest";
import HomeInspectionPublicView from "../components/HomeInspections/HomeInspectionPublicView";
import HomeInspectionVerify from "../components/HomeInspections/HomeInspectionVerify";
import HomeInspectionForgot from "../components/HomeInspections/HomeInspectionForgot";
import HomeInspections from "../components/HomeInspections/HomeInspections";
import HomeInspection from "../components/HomeInspections/HomeInspection";
import HomeInspectionCancel from "../components/HomeInspections/HomeInspectionCancel";
import HomeInspectionAssign from "../components/HomeInspections/HomeInspectionAssign";
import HomeInspectionIndex from "../components/HomeInspections/HomeInspectionIndex";
import HomeInspectionLookup from "../components/HomeInspections/HomeInspectionLookup";
import HomeInspectionAdd from "../components/HomeInspections/HomeInspectionAdd";

// Enrollment
import Enrollment from "../components/Enrollment";
import CertificationEntitlement from "../components/CertificationEntitlement";

import Landing from "../components/Landing";
import CourseCatalog from "../components/Courses/CourseCatalog";
import UserProfile from "../components/UserProfile";

// Audits
import Audits from "../components/Audits/Audits";
import Audit from "../components/Audits/Audit";
import AddAudit from "../components/Audits/AddAudit";
import AddAuditModule from "../components/Audits/Modules/AddAuditModule";
import AuditModule from "../components/Audits/Modules/AuditModule";
import AddAuditQuestion from "../components/Audits/Questions/AddAuditQuestion";
import AuditQuestion from "../components/Audits/Questions/AuditQuestion";
import AddAuditQuestionOption from "../components/Audits/QuestionOptions/AddAuditQuestionOption";
import AuditQuestionOption from "../components/Audits/QuestionOptions/AuditQuestionOption";

import AuditForm from "../components/Audits/Form/AuditForm";

// Errors
import NotFound from "../components/NotFound";
import HomeOwnerAuditForm from "../components/Audits/HomeOwnerView/HomeOwnerAuditForm";
import HomePage from "../components/HomePage";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    getStoredAuthToken() !== undefined,
  );

  const login = (token) => {
    storeAuthToken(token);
    setIsLoggedIn(true);
  };

  const logout = () => {
    removeStoredAuthToken();
    setIsLoggedIn(false);
    history.push("/login");
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <Root
            isLoggedIn={isLoggedIn}
            logoutCallBack={logout}
            history={history}
          ></Root>
        }
      >
        <Route
          index
          element={
            <ProtectedRoute>
              <Landing />
            </ProtectedRoute>
          }
        />
        {/*Login*/}
        <Route path="login" element={<Login loginCallBack={login} />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="forgot-password" element={<ForgotPassword />} />

        {/*Courses*/}
        <Route
          path="courses"
          element={
            <ProtectedRoute>
              <Courses />
            </ProtectedRoute>
          }
        >
          <Route index element={<CourseCatalog />} />
          <Route path="add" element={<AddCourse />} />
          <Route path=":id" element={<CourseView />} />
          <Route path=":id/edit" element={<CourseEdit />} />
          <Route path=":id/modules/:moduleID" element={<CourseModule />} />
          <Route path=":id/modules/Add" element={<AddCourseModule />} />
          <Route
            path=":id/modules/:moduleID/pages/:pageID"
            element={<CoursePage />}
          />
          <Route
            path=":id/modules/:moduleID/pages/Add"
            element={<AddCoursePage />}
          />
          <Route
            path=":id/modules/:moduleID/preview"
            element={<ModulePreview />}
          />
        </Route>

        {/*Enrollment*/}
        <Route
          path="enrollment"
          element={
            <ProtectedRoute>
              {" "}
              <Enrollment />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="enrollment/:id"
          element={
            <ProtectedRoute>
              <CertificationEntitlement />
            </ProtectedRoute>
          }
        />

        {/*Quizzes*/}
        <Route path="quizzes" element={<Quizzes />} />
        <Route path="quizzes/:id" element={<Quiz />} />
        <Route path="quizzes/add" element={<AddQuiz />} />
        <Route
          path="quizzes/:id/questions/:questionID"
          element={<QuizQuestion />}
        />
        <Route path="quizzes/:id/questions/add" element={<AddQuizQuestion />} />
        <Route
          path="quizzes/:id/questions/:questionID/answers/:answerID"
          element={<QuizAnswer />}
        />
        <Route
          path="quizzes/:id/questions/:questionID/answers/add"
          element={<AddQuizAnswer />}
        />
        <Route path="quizzes/:id/questions/preview" element={<QuizPreview />} />

        {/*Users*/}
        <Route path="users" element={<Users />} />
        <Route path="users/:id" element={<User />} />
        <Route path="users/add" element={<AddUser />} />

        <Route
          path="user-profile"
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />

        {/*Home Inspections*/}
        <Route
          path="request-home-inspection"
          element={<HomeInspectionRequest />}
        />
        <Route path="view-home-inspection" element={<HomeInspectionIndex />}>
          <Route index element={<HomeInspectionLookup />} />
          <Route path="retrieved" element={<HomeInspectionPublicView />} />
          <Route path="inspection/:id" element={<HomeOwnerAuditForm />} />
        </Route>
        <Route
          path="verify-home-inspection"
          element={<HomeInspectionVerify />}
        />
        <Route path="forgot-access-code" element={<HomeInspectionForgot />} />
        <Route
          path="home-inspections"
          element={
            <ProtectedRoute>
              <HomeInspections />
            </ProtectedRoute>
          }
        />
        <Route
          path="home-inspections/add"
          element={
            <ProtectedRoute>
              <HomeInspectionAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path="home-inspection/:id"
          element={
            <ProtectedRoute>
              <HomeInspection />
            </ProtectedRoute>
          }
        />

        <Route
          path="home-inspection/:id/cancel"
          element={
            <ProtectedRoute>
              <HomeInspectionCancel />
            </ProtectedRoute>
          }
        />
        <Route
          path="home-inspection/:id/assign"
          element={
            <ProtectedRoute>
              <HomeInspectionAssign />
            </ProtectedRoute>
          }
        />
        <Route
          path="home-inspection/:id/inspection"
          element={
            <ProtectedRoute>
              <AuditForm />
            </ProtectedRoute>
          }
        />

        {/*Audits*/}
        <Route
          path="audits"
          element={
            <ProtectedRoute>
              <Audits />
            </ProtectedRoute>
          }
        />
        <Route
          path="audits/:id"
          element={
            <ProtectedRoute>
              <Audit />
            </ProtectedRoute>
          }
        />
        <Route
          path="audits/add"
          element={
            <ProtectedRoute>
              <AddAudit />
            </ProtectedRoute>
          }
        />
        <Route
          path="audits/:id/modules/:moduleID"
          element={
            <ProtectedRoute>
              <AuditModule />
            </ProtectedRoute>
          }
        />
        <Route
          path="audits/:id/modules/add"
          element={
            <ProtectedRoute>
              <AddAuditModule />
            </ProtectedRoute>
          }
        />
        <Route
          path="audits/:id/modules/:moduleID/questions/add"
          element={
            <ProtectedRoute>
              <AddAuditQuestion />
            </ProtectedRoute>
          }
        />
        <Route
          path="audits/:id/modules/:moduleID/questions/:questionID"
          element={
            <ProtectedRoute>
              <AuditQuestion />
            </ProtectedRoute>
          }
        />
        <Route
          path="audits/:id/modules/:moduleID/questions/:questionID/options/add"
          element={
            <ProtectedRoute>
              <AddAuditQuestionOption />
            </ProtectedRoute>
          }
        />
        <Route
          path="audits/:id/modules/:moduleID/questions/:questionID/options/:optionID"
          element={
            <ProtectedRoute>
              <AuditQuestionOption />
            </ProtectedRoute>
          }
        />

        <Route
          path="/home"
          element={<HomePage />}
        />

        {/*Errors*/}
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <NotFound />
            </ProtectedRoute>
          }
        />
      </Route>,
    ),
  );

  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;

import React from 'react';
import { DateTime } from 'luxon';

function UserCertification({
    certification,
    certEntitlements,
    handleGrantCertificationCallback,
    handleRenewCertificationCallback,
    handleEnrollUserCallback,
    handleRevokeCertificationCallback
}) {
    const certID = certification.certificationID;
    const certEntitlement = certEntitlements
        .find(entitlement => entitlement.certificationID === certID);

    const getCertificationEntitlementStatus = () => {
        if (certEntitlement === undefined) return 'N/A';
        return certEntitlement.certificationStatus;
    }

    const formatDate = (date) => {
        return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    }

    const earnedDate = () => {
        if (certEntitlement === undefined) return '';
        return formatDate(certEntitlement.approveDeniedDate);
    }

    const expiryDate = () => {
        if (certEntitlement === undefined) return '';
        return DateTime.fromISO(certEntitlement.approveDeniedDate)
            .plus({ years: certification.yearsValid }).toLocaleString(DateTime.DATE_MED);
    }

    const canApprove = () => {
        if (certEntitlement === undefined) return true;
        return certEntitlement.certificationStatus !== 'Active';
    }

    const canRenew = () => {
        if (certEntitlement === undefined) return false;
        if (certEntitlement.approveDeniedDate === undefined) return false;
        //If the cert is expiring in the next 90 days we can renew it 
        let expiry = DateTime.fromISO(certEntitlement.approveDeniedDate)
            .plus({ years: certification.yearsValid });
        let now = DateTime.now();
        return expiry.diff(now, 'days').days <= 90;
    }

    const canRevoke = () => {
        if (certEntitlement === undefined) return false;
        return certEntitlement.certificationStatus !== 'Denied' &&
            certEntitlement.certificationStatus !== 'Expired' &&
            certEntitlement.certificationStatus !== 'Revoked' &&
        certEntitlement.certificationStatus !== 'Requested';
    }

    const canEnroll = () => {
        return certEntitlement === undefined;
    }

    const hasCertification = () => {
        if (certEntitlement === undefined) return false;
        return certEntitlement.certificationStatus === 'Active' ||
            certEntitlement.certificationStatus === 'Renewal' ||
            certEntitlement.certificationStatus === 'Renewal - Ready for Approval';
    }

    const handleGrantCertification = (e) => {
        e.preventDefault();
        handleGrantCertificationCallback(certID);
    }

    const handleRenewCertification = (e) => {
        e.preventDefault();
        handleRenewCertificationCallback(certID);
    }

    const handleEnrollUser = (e) => {
        e.preventDefault();
        handleEnrollUserCallback(certID);
    }

    const handleRevokeCertification = (e) => {
        e.preventDefault();
        handleRevokeCertificationCallback(certID);
    }

    return (
        <li class={`list-group-item d-flex justify-content-between align-items-star  ${hasCertification() ? 'bg-gray-200' : 'bg-transparent'}`}
            key={certID} >
            <div class="ms-2 me-auto">
                <div class="fw-bold">
                    {certification.certificationName}&nbsp;
                    <i class="fa-solid fa-scroll" style={{ 'color': certification.badgeColour }}></i>
                </div>
                <div>
                    Status: {getCertificationEntitlementStatus()}
                </div>
                {hasCertification() && <div>
                    Earned: {earnedDate()}
                </div>}
                {hasCertification() && <div>
                    Expires: {expiryDate()}
                </div>}
            </div>
            <div class="nav-item dropdown float-right">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Actions
                </a>
                <ul class="dropdown-menu">
                    <li>
                        <a class={canEnroll() ?
                            "dropdown-item" : "dropdown-item disabled"} href="#"
                            onClick={handleEnrollUser}>
                            Enroll
                        </a>
                    </li>
                    <li>
                        <a class={canApprove() ?
                            "dropdown-item" : "dropdown-item disabled"} href="#"
                            onClick={handleGrantCertification}>
                            Grant
                        </a>
                    </li>
                    <li>
                        <a class={canRenew() ?
                            "dropdown-item" : "dropdown-item disabled"} href="#"
                            onClick={handleRenewCertification}>
                            Renew
                        </a>
                    </li>
                    <li>
                        <a class={canRevoke() ?
                            "dropdown-item" : "dropdown-item disabled"} href="#"
                            onClick={handleRevokeCertification}>
                            Revoke
                        </a>
                    </li>
                </ul>
            </div>
        </li >
    );
}

export default UserCertification;

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import api from "../../shared/utils/api";

// TODO: I'm not a designer, this page needs some love but this is a start
export default function HomeInspectionPublicView() {
  const [homeInspection, setHomeInspection] = useState(null);

  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate("/view-home-inspection");
      return;
    }

    setHomeInspection(state.homeInspection);
  }, []);

  if (!homeInspection) {
    return (
      <div>
        <h4>Loading...</h4>
      </div>
    );
  }

  const handleRetrieveAnotherHomeInspection = (e) => {
    e.preventDefault();
    navigate("/view-home-inspection");
  };

  const handleRequestReinspection = (e) => {
    e.preventDefault();
    api
      .post(
        "/request-reinspection?email=" +
          homeInspection.homeOwnerEmailAddress +
          "&accessCode=" +
          homeInspection.homeInspectionAccessCode,
      )
      .then(
        () => {
          toast.success("Reinspection requested!", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  const handleViewAudit = (e) => {
    e.preventDefault();
    navigate(
      "/view-home-inspection/inspection/" + homeInspection.homeInspectionID,
      {
        state: {
          auditID: homeInspection.auditID,
          homeInspectionID: homeInspection.homeInspectionID,
          accessCode: homeInspection.homeInspectionAccessCode,
          userEmail: homeInspection.homeOwnerEmailAddress,
        },
      },
    );
  };

  const inspectorAssigned = homeInspection.assignedInspectorID !== null;

  const completedStatus = ["Completed", "Certified", "Re-Inspection"];
  const canViewAudit = completedStatus.includes(
    homeInspection.homeInspectionStatus,
  );

  // If the home inspection is cancelled, show the cancellation note over the assignedInspector note
  const notes = homeInspection.CancellationNote || "";

  return (
    <div>
      <div className="form-container">
        <h4>Home Assessment Request</h4>
        <Row>
          <Col>
            <label className="font-weight-bold">Contact Info</label>
            <p>{homeInspection.homeOwnerPhoneNumber}</p>
            <p>{homeInspection.homeOwnerEmailAddress}</p>
          </Col>
          <Col>
            <label className="font-weight-bold">Home Assessment Status</label>
            <p>{homeInspection.homeInspectionStatus}</p>
          </Col>
          {inspectorAssigned && (
            <Col>
              <label className="font-weight-bold">Inspector</label>
              <p>
                {homeInspection.assignedInspectorFirstName +
                  " " +
                  homeInspection.assignedInspectorLastName}
              </p>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <label className="font-weight-bold">Address</label>
            <p>
              {homeInspection.address}, {homeInspection.city},{" "}
              {homeInspection.province}, {homeInspection.postalCode}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="font-weight-bold">Notes</label>
            <textarea
              className="form-control"
              rows="3"
              value={notes}
              disabled={true}
            ></textarea>
          </Col>
        </Row>

        <div className="form-group">
          <button
            className="btn-standard margin-right-10"
            type="submit"
            onClick={handleRetrieveAnotherHomeInspection}
          >
            Go Back
          </button>
          {canViewAudit && (
            <button
              className="btn-standard margin-right-10"
              type="submit"
              onClick={handleViewAudit}
            >
              View Assessment
            </button>
          )}
          <button
            className="btn-standard margin-right-10"
            type="submit"
            onClick={handleRequestReinspection}
          >
            Request Re-Inspection
          </button>
        </div>
      </div>
    </div>
  );
}

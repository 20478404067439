import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';


function FormGroupInput({ controlId,
  label,
  required,
  onChange,
  type,
  invalidFeedback,
  disabled,
  pattern,
  value,
  placeholder,
  isInvalid,
  className
}) {
    return (
        <Form.Group as={Col} controlId={controlId} className={`form-group ${className}`}>
          <Form.Label className="font-bold">{label}</Form.Label>
          <Form.Control
            required={required}
            onChange={onChange}
            type={type}
            value={value}
            disabled={disabled ? true : false}
            pattern={pattern ? pattern : null}
            placeholder={placeholder ? placeholder : null}
            isInvalid={isInvalid ? true : false}
          />
          <Form.Control.Feedback type="invalid">
            {invalidFeedback}
          </Form.Control.Feedback>
        </Form.Group>
    );
}

export default FormGroupInput;

import { ChevronRight, ClipboardPen, Eye } from 'lucide-react'
import React from 'react'
import { NavLink } from 'react-router-dom'

function HomePage() {
  return (
    <div className='grid grid-cols-12 gap-8 pb-14'>
      <div className='col-span-12 md:col-span-7 lg:col-span-8 space-y-12'>
        <div className='relative rounded-lg overflow-hidden'>
          <img 
            src="/homepage-banner.png" 
            alt="A women taking a photo with a tablet." 
            className='object-cover w-full h-full'
          />
          <div className='z-10 absolute bottom-4 left-6 lg:inset-14 space-y-6 lg:space-y-10'>
            <img src="/fsbc-logo.png" className='w-[140px] md:w-[200px] xl:w-[240px] object-contain'/>
            <h1 className='max-w-[30rem] leading-none xl:text-[54px] text-white'>
              Request or view your assessment.
            </h1>
          </div>
          <div className='absolute inset-0 w-full h-full bg-[#22222280]' />
        </div>
        <div>
          <h2>About the Wildfire Mitigation Program Assessment</h2>
          <p className='mt-4 text-lg'>A FireSmart BC Wildfire Mitigation Program Assessment provides residents with:</p>
          <ul className='pl-2.5 list-disc list-outside text-lg text-pretty'>
            <li>An in-depth, on-site assessment conducted by experienced fire professionals.</li>
            <li>An opportunity for property owners to identify mitigation actions unique to their property.</li>
            <li>A detailed follow-up report with customized mitigation actions designed to measurably reduce the wildfire risk to your property.</li>
            <li>An opportunity to earn FireSmart acknowledgement for your mitigation achievements through a certificate. The certificate is given upon successful completion of required mitigation actions and an on-site follow-up inspection.</li>
            <li>A mitigation certification that can be shared with participating insurance providers to showcase mitigation activities and may be eligible for insurance incentives or discounts. Please note: FireSmart does not share the assessment or any details of the assessment with the insurance industry.</li>
            <li>FireSmart Home signage for their property (upon successful completion of required mitigation actions and an on-site follow-up inspection).</li>
          </ul>
        </div>
      </div>
      <div className='col-span-12 md:col-span-5 lg:col-span-4 space-y-8'>
        <div className='p-4 border rounded-lg'>
          <Eye size={40} className='mt-3.5 text-[#EFCA00]' />
          <h3 className='mt-4'>Have an assessment code?</h3>
          <p className='mt-2.5 text-lg text-balance'>Did you receive an access code to review your assessment details?</p>
          <NavLink to="/view-home-inspection" className='pb-2.5 flex items-center gap-2.5 font-semibold text-black group'>
            <span>View my assessment</span> 
            <ChevronRight size={18} className='-mt-1 group-hover:-translate-x-0.5 transition-transform duration-300' />
          </NavLink>
        </div>
        <div className='p-4 border rounded-lg'>
          <ClipboardPen size={40} className='mt-3.5 text-[#EFCA00]' />
          <h3 className='mt-4'>Looking to get an assessment?</h3>
          <p className='mt-2.5 text-lg text-balance'>Request an assessment to receive a detailed report on your property's wildfire risk and mitigation actions.</p>
          <NavLink to="/request-home-inspection" className='pb-2 flex items-center gap-2.5 font-semibold text-black group'>
            <span>Request an assessment</span>  
            <ChevronRight size={18} className='-mt-1 group-hover:-translate-x-0.5 transition-transform duration-300' />
          </NavLink>
        </div>
        <div>
          <img 
            src="/homepage-image-two.png" 
            alt="A women taking a photo with a tablet." 
            className='rounded-lg'
          />
        </div>
      </div>
    </div>
  )
}

export default HomePage
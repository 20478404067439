import React from "react";
import { useState, useEffect } from "react";
import api from "../../shared/utils/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormGroupInput from "../../shared/components/FormGroupInput";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FormGroupProvinceDropdownInput from "../../shared/components/FormGroupProvinceDropdownInput";
import FormGroupAuditDropDownInput from "../../shared/components/FormGroupAuditDropDownInput";
import PostalCodeInput from "../../shared/components/PostalCodeInput";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import { isUserAdmin } from "../../shared/utils/authToken";

function HomeInspectionAdd() {
  
  const [validated, setValidated] = useState(false);
  const [auditID, setAuditID] = useState(1);
  const [homeOwnerEmail, setHomeOwnerEmail] = useState("");
  const [homeOwnerPhoneNumber, setHomeOwnerPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("BC");
  const [postalCode, setPostalCode] = useState("");
  const [territories, setTerritories] = useState([]);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState(1);

  const handleTerritoryChange = (e) => {
    setSelectedTerritoryId(e.target.value);
  };

  useEffect(() => {
    const requestUrl = isUserAdmin() ? "/territories" : "/territories-for-user";
    api.get(requestUrl).then(
      (response) => {
        setTerritories(response);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  }, []);

  const newInspection = () => {
    // TODO: input sanitization
    api
      .post("/home-inspections", {
        //TODO: Audit ID will come from what type of Audit the user is requesting
        // since we don't have that we are going to hardcode it to 1
        auditID: Number(auditID),
        homeInspectionStatus: "Request Verified",
        homeOwnerEmailAddress: homeOwnerEmail,
        // TODO: phone number sanitization (10 digits, no spaces, no dashes, prepend with +1)
        homeOwnerPhoneNumber:
          homeOwnerPhoneNumber === "" ? null : "+1" + homeOwnerPhoneNumber,
        address: address,
        city: city,
        province: province,
        // TODO: postal code sanitization (7 characters, 1 space in middle)
        postalCode: postalCode,
        territoryId: +selectedTerritoryId,
      })
      .then(
        () => {
          toast.success("New assessment created!", {
            autoClose: 2000,
          });
          navigate("/home-inspections");
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    newInspection();
    //navigate("/home-inspections");
  };

  return (
    <div>
      <h2> New Assessment </h2>
      <p>Use this form to create a new assessment.</p>
      <div class="row">
        <div class="col">
          <HomeInspectionNewForm
            setAuditID={setAuditID}
            auditID={auditID}
            homeOwnerEmail={homeOwnerEmail}
            setHomeOwnerEmail={setHomeOwnerEmail}
            homeOwnerPhoneNumber={homeOwnerPhoneNumber}
            setHomeOwnerPhoneNumber={setHomeOwnerPhoneNumber}
            address={address}
            setAddress={setAddress}
            city={city}
            setCity={setCity}
            province={province}
            setProvince={setProvince}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            handleSubmit={handleSubmit}
            validated={validated}
            handleTerritoryChange={handleTerritoryChange}
            selectedTerritoryId={selectedTerritoryId}
            territories={territories}
          />
        </div>
      </div>
    </div>
  );
}

function HomeInspectionNewForm({
  setAuditID,
  auditID,
  setHomeOwnerEmail,
  setHomeOwnerPhoneNumber,
  setAddress,
  setCity,
  province,
  postalCode,
  setProvince,
  setPostalCode,
  handleSubmit,
  validated,
  handleTerritoryChange,
  selectedTerritoryId,
  territories,
}) {
  return (
    <div className="col">
      <div className="form-container">
        <h4>New Assessment Form</h4>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <FormGroupAuditDropDownInput
              value={auditID}
              onChange={(e) => setAuditID(e.target.value)}
              required={true}
            />
          </Row>
          <Row>
            <Form.Group as={Col} controlId="territory" className="form-group">
              <Form.Label className="font-bold">Agency</Form.Label>
              <Form.Select
                className="form-select"
                onChange={handleTerritoryChange}
                value={selectedTerritoryId}
              >
                {territories.map((territory) => (
                  <option key={territory.id} value={territory.id}>
                    {territory.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Must select one of the territories listed.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <FormGroupInput
              controlId="homeOwnerEmail"
              label="Home Owner's Email Address"
              required={true}
              type="email"
              invalidFeedback="Must provide a valid email."
              onChange={(e) => setHomeOwnerEmail(e.target.value)}
            />
          </Row>
          <Row>
            <FormGroupInput
              controlId="homeOwnerPhoneNumber"
              label="Home Owner's Phone Number"
              type="tel"
              invalidFeedback="Must provide a phone number in the format 123-456-7890 or 1234567890."
              onChange={(e) => setHomeOwnerPhoneNumber(e.target.value)}
              pattern="(\d{3}-?\d{3}-?\d{4})|(\d{10})"
            />
          </Row>
          <Row>
            <FormGroupInput
              controlId="address"
              label="Address"
              required={true}
              type="text"
              invalidFeedback="Must provide an address with allowed special characters .:-_', and allowed spaces."
              onChange={(e) => setAddress(e.target.value)}
              pattern="^[a-zA-Z0-9 .:,-_']+$"
            />
          </Row>
          <Row>
            <FormGroupInput
              controlId="city"
              label="City"
              required={true}
              type="text"
              invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
              onChange={(e) => setCity(e.target.value)}
              pattern="^[a-zA-Z0-9 .:,-_']+$"
            />
          </Row>
          <Row>
            <FormGroupProvinceDropdownInput
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              required={true}
              placeHolderRequired={true}
            />
          </Row>
          <Row>
            <PostalCodeInput
              controlId="postalCode"
              label="Postal Code"
              required={true}
              type="text"
              invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
              value={postalCode}
              onChange={(e) => setPostalCode(e)}
              pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
            />
          </Row>
          <Row className="pt-3 mb-2">
            <Form.Group class="form-group">
              <Button className="btn-standard margin-right-10" type="submit">
                Create Assessment
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default HomeInspectionAdd;

import React from "react";
import { useState, useEffect } from "react";
import CertificationListing from "./CertificationListing";
import api from "../shared/utils/api";
import { useNavigate } from "react-router-dom";
import CourseListingHorizontal from "./Courses/CourseListingHorizontal";
import { toast } from "react-toastify";
import { Row, Col, Stack } from "react-bootstrap";
import { getLoggedInUser } from "../shared/utils/authToken";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";
import { LuClipboardList } from "react-icons/lu";
import { IoEyeOutline } from "react-icons/io5";
import { CiClock1 } from "react-icons/ci";

function Landing() {
  const [user, setUser] = useState({});
  const [certifications, setCertifications] = useState([]);
  const [courses, setCourses] = useState([]);
  const [assignedHomeInspections, setAssignedHomeInspections] = useState([]);
  const navigate = useNavigate();

  const canViewInspections = user.canAssess;

  const fetchUserCertifications = () => {
    api.get("/user-certifications").then(
      (response) => {
        if (response === null) return;
        if (response === undefined) return;
        setCertifications(response);
      },
      (error) => {
        toast.error(error.message, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  };

  const fetchUserCourses = () => {
    api.get("/courses-user-progress").then(
      (response) => {
        if (response === null) return;
        if (response === undefined) return;
        setCourses(response);
      },
      (error) => {
        toast.error(error.message, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  };

  const certificationListings = () => {
    if (
      certifications === null ||
      certifications === undefined ||
      certifications.length === 0
    ) {
      return (
        <p>
          You haven't earned any certifications yet. Enroll in a course on the
          Courses page to get started.
        </p>
      );
    }

    return certifications
      .sort((a, b) => a.certificationName.localeCompare(b.certificationName))
      .map((certification) => (
        <CertificationListing certification={certification} />
      ));
  };

  const courseListings = () => {
    if (courses === null || courses === undefined) {
      return (
        <p>
          No courses currently in progress. Click on Courses to get started!
        </p>
      );
    }

    let filteredCourses = courses
      .filter(
        // Check if the course status is an empty string and then sort in reverse order so we show
        // the "in progress" items at the top
        (course) => course.status !== "",
      )
      .sort((a, b) => b.status.localeCompare(a.status));

    if (filteredCourses.length === 0) {
      return (
        <p>
          No courses currently in progress. Click on Courses to get started!
        </p>
      );
    }

    return filteredCourses.map((course) => (
      <CourseListingHorizontal
        course={course.course}
        status={course.status}
        handleRowClick={handleRowClick}
      />
    ));
  };

  const fetchHomeInspections = () => {
    api.get("/home-inspections?filter=landing").then(
      (response) => {
        if (response === null) return;
        if (response === undefined) return;
        setAssignedHomeInspections(response);
      },
      (error) => {
        toast.error(error.message, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  };

  const handleHomeInspectionGaugeClick = (filter) =>
    navigate("/home-inspections/?filter=" + filter);
  const temp = 0;
  const openHomeInspectionsAssignedToMe =
    assignedHomeInspections?.filter(
      (h) =>
        h.homeInspectionStatus !== "Completed" &&
        h.homeInspectionStatus !== "Cancelled",
    ).length || 0;

  const changesToBeReviewedCount =
    assignedHomeInspections?.filter(
      (h) => h.homeInspectionStatus === "Changes Submitted",
    ).length || 0;

  const pendingChangesCount =
    assignedHomeInspections?.filter(
      (h) => h.homeInspectionStatus === "Changes Requested",
    ).length || 0;

  const myHomeInspections = () => {
    if (
      assignedHomeInspections === null ||
      assignedHomeInspections === undefined ||
      assignedHomeInspections.length === 0
    ) {
      return (
        <p>No Assessments assigned. Click on Assessments to get started!</p>
      );
    }

    const user = getLoggedInUser();

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-between">
        <div
          className="count-container p-6 border border-border rounded-md shadow-sm cursor-pointer hover:bg-gray-200 transition-colors duration-300"
          onClick={() =>
            handleHomeInspectionGaugeClick(user.firstname + " " + user.lastname)
          }
        >
          <div className="flex flex-row items-center justify-between space-y-0 pb-6">
          <div className="text-base font-bold">Open Assessments</div>
          <LuClipboardList className="h-5 w-5 text-muted-foreground" />
          </div>

          <div className="text-3xl font-bold">{openHomeInspectionsAssignedToMe}</div>
        </div>

        <div
          className="count-container p-6 border border-border rounded-md shadow-sm cursor-pointer hover:bg-gray-200 transition-colors duration-300"
          onClick={() => handleHomeInspectionGaugeClick("Changes Submitted")}
        >
          <div className="flex flex-row items-center justify-between space-y-0 pb-6">
            <div className="text-base font-bold">Changes to Review</div>
            <IoEyeOutline className="h-5 w-5 text-muted-foreground" />
          </div>
          <div className="text-3xl font-bold">{changesToBeReviewedCount}</div>
        </div>

        <div
          className="count-container p-6 border border-border rounded-md shadow-sm cursor-pointer hover:bg-gray-200 transition-colors duration-300"
          onClick={() => handleHomeInspectionGaugeClick("Changes Requested")}
        >
          <div className="flex flex-row items-center justify-between space-y-0 pb-6">
            <div className="text-base font-bold">Pending Changes</div>
            <CiClock1 className="h-5 w-5 text-muted-foreground" />
          </div>
          <div className="text-3xl font-bold">{pendingChangesCount}</div>
        </div>
      </div>
    );
  };

  const handleRowClick = (course) => {
    navigate("/courses/" + course.courseID.toString(), {
      state: { course: course },
    });
  };

  useEffect(() => {
    fetchUserCertifications();
    fetchUserCourses();

    const user = getLoggedInUser();
    setUser(user);
  }, []);

  useEffect(() => {
    if (canViewInspections) {
      fetchHomeInspections();
    }
  }, [canViewInspections]);

  return (
    <div>
      <h1>Home</h1>
      <Stack direction="vertical" gap={3}>
        {canViewInspections && (
          <Row>
            <Col>
              <div className="mt-2.5">
                <h4>My Assessments</h4>
                <div className="landing-container-content">
                  {myHomeInspections()}
                </div>
              </div>
            </Col>
          </Row>
        )}
        <div className="mt-4 grid grid-cols-12 gap-4">
          {/* <div className="col-span-full lg:col-span-8">
            <div className="">
              <h4>My Training</h4>
              <div className="">
                {courseListings()}
              </div>
            </div>
          </div> */}
          {/* <div className="col-span-full lg:col-span-4">
            <div className="">
              <h4>My Certifications</h4>
              <div className="">
                {certificationListings()}
              </div>
            </div>
          </div> */}
        </div>
      </Stack>
    </div>
  );
}

export default Landing;

import { Image, Loader } from 'lucide-react';
import React, { useEffect, useState } from 'react';

function UploadImages({ imageFiles, setImageFiles, setImagesPreview }) {

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileSelection = (selectedFiles) => {

    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    const validFiles = Array.from(selectedFiles).filter((file) =>
      allowedFileTypes.includes(file.type)
    );

    if (validFiles?.length > 0) {
      setIsLoading(true);
      setTimeout(() => {
        setImageFiles(validFiles);
        const imageUrls = validFiles.map((file) => URL.createObjectURL(file));
        setImagesPreview(imageUrls);
        setIsLoading(false);
      }, 4000)
    } else {
      alert('No valid file types selected. Please select PNG, JPEG or JPG files.');
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length > 0) {
      const updatedFiles = [...imageFiles, ...selectedFiles];
      handleFileSelection(updatedFiles);
    }
  };

  const handleCameraCapture = async (event) => {
    const capturedFile = event.target.files[0];
    if (capturedFile) {
      const updatedFiles = [...imageFiles, capturedFile];
      handleFileSelection(updatedFiles);
    }
  };

  useEffect(() => {
    return () => {
      imageFiles.forEach((file) => URL.revokeObjectURL(file));
    };
  }, [imageFiles]);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles?.length > 0) {
      const updatedFiles = [...imageFiles, ...droppedFiles];
      handleFileSelection(updatedFiles);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  return (
    <div
      className={`mt-2.5 ${isDraggingOver ? 'bg-gray-200' : 'upload_con'}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        padding: '30px',
        border: '1px dashed #000',
        borderRadius: '8px'
      }}
    >
      {isLoading ? (
        <div className='h-[150px] flex flex-col items-center justify-center gap-3'>
          <Loader className='mt-3 animate-spin' />
          <p>Processing Image...</p>
        </div>
      ): (
        <div className='h-[150px] flex flex-col items-center justify-center gap-2'>
          <Image />
          <p className="mt-1.5">
            Drag & drop an image here
          </p>
          <div className="-mt-2 flex gap-2" >
            <input
              type="file"
              id="ctl"
              accept=".png, .jpeg, .jpg"
              multiple
              onChange={handleFileChange}
              style={{
                opacity: 0,
                position: 'absolute',
                zIndex: -1,
              }}
            />
            <label htmlFor="ctl" className="">
              or <span className='ml-1 pt-1.5 pb-0.5 px-2.5 rounded-full text-sm antialiased bg-[#efc901] hover:bg-[#f3d646] text-black cursor-pointer'>Choose File</span>
            </label>
            <input
              type="file"
              id="camera"
              accept="image/*"
              capture="environment"
              onChange={handleCameraCapture}
              style={{
                opacity: 0,
                position: 'absolute',
                zIndex: -1,
              }}
              className='xl:hidden'
            />
            <label htmlFor="camera" className='xl:hidden'>
              <span className='pt-1.5 pb-0.5 px-2.5 rounded-full text-sm antialiased bg-[#efc901] hover:bg-[#f3d646] text-black cursor-pointer'>Take Photo</span>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImages;
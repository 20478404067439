import React from 'react';
import { useState, useEffect } from 'react';
import api from '../../shared/utils/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroupInput from "../../shared/components/FormGroupInput";
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

function HomeInspectionAssign() {
    const [validated, setValidated] = useState(false);
    const { state } = useLocation();

    const [assignedInspectorID, setAssignedInspectorID] = useState('');
    const [assignedInspectorNote, setAssignedInspectorNote] = useState('');
    const [lstInspectors, setLstInspectors] = useState([]);

    const submitAssignUser = () => {
        api.post('/assign-home-inspection', {
            targetHomeInspectionID: Number(state.homeInspectionId),
            targetInspectorUserID: parseInt(assignedInspectorID),
            assignedInspectorNote: assignedInspectorNote,
        }).then(
            () => {
                toast.success('Successfully Assigned home assessment', {
                    autoClose: 2000
                });
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const navigate = useNavigate();

    const handleCancel = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setValidated(true);
        submitAssignUser();
        navigate(-1);
    }

    useEffect(() => {
        const fetchInspectors = () => {
            api.get('/firesmart-users-assignable/' + Number(state.homeInspectionId)).then(
                (response) => {
                    if (response === null) {
                        toast.error('No inspectors available', { autoClose: ERROR_MESSAGE_DURATION });
                        return;
                    }
                    if (response === undefined) {
                        toast.error('No inspectors available', { autoClose: ERROR_MESSAGE_DURATION });
                        return;
                    }
                    if (response.length === 0) {
                        toast.error('No inspectors available', { autoClose: ERROR_MESSAGE_DURATION });
                        return;
                    }
                    setLstInspectors(response);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                });
        }

        fetchInspectors();
    }, []);

    return (
        <div>
            <h2>Assign Home Assessment                
            </h2>
            <p>Use this form to assign an inspector to a home assessment.</p>
            <div class="row">
                <div class="col">
                    <div class="form-container">
                        <h4>Assign Inspector</h4>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} controlId="assignedInspector" class="form-group">
                                    <Form.Label className="font-bold">Assigned Assessor</Form.Label>
                                    <Form.Select
                                        required={true}
                                        class="form-select"
                                        onChange={(e) => setAssignedInspectorID(e.target.value)}
                                        className="mb-3"
                                    >
                                        <option value="" selected disabled hidden>Select Inspector</option>
                                        {lstInspectors.map((inspector) => (
                                            <option key={inspector.userID} value={inspector.userID}>{inspector.firstname} {inspector.lastname}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Must select one of the inspectors listed.
                                    </Form.Control.Feedback>
                                    <Row>
                                        <FormGroupInput
                                            controlId="assignedInspectorNote"
                                            label="Note"
                                            type="text"
                                            value={assignedInspectorNote}
                                            onChange={(e) => setAssignedInspectorNote(e.target.value)}
                                            placeholder="Please enter a note for the inspector."
                                            required={true}
                                            invalidFeedback="Please enter a note for the inspector."
                                        />
                                    </Row>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group class="form-group">
                                    <Button
                                        type="submit"
                                        className="btn-standard margin-right-10"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="btn-standard danger"
                                        onClick={handleCancel}>Cancel
                                    </Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeInspectionAssign;

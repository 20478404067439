import React from "react";
import { useState } from "react";
import MultiSelectOptions from "../Form/MultiSelectOptions";
import CheckBox from "../../../shared/components/CheckBox";
import ImageContainer from "../Form/ImageContainer";
import ImageFull from "./ImageFull";
import _ from "lodash";
import { AiFillQuestionCircle } from "react-icons/ai";

function HomeOwnerQuestion({
  questionID,
  questionContent,
  questionAnswers,
  inspectorComment,
  images,
  requiresActionValue,
}) {
  const [selectedOptions] = useState(
    questionAnswers
      ?.filter((a) => a.selected)
      .map((a) => a.auditQuestionOptionID),
  );
  const [requiresAction] = useState(requiresActionValue);
  const [imageAttachments] = useState(images || []);

  function groupByHeaderAdvanced(xs, key) {
    if (!xs) return [];
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.values.header === v);

      if (el && v !== "") {
        el.values.options.push(x);
      } else {
        rv.push({ key: rv.length, values: { header: v, options: [x] } });
      }

      return rv;
    }, []);
  }

  const groupedAnswers = _.sortBy(
    groupByHeaderAdvanced(questionAnswers, "header"),
    (a) => a.values.sortOrder,
  );

  return (
    <div>
      <div
        className="audit-question-container border-border border rounded-md p-4 mb-4 shadow-sm"
        key={questionID}
      >
        <h4 className="flex flex-row gap-2 flex-nowrap">
          <AiFillQuestionCircle className="h-5 w-5 inline-block flex-shrink-0 text-black  rounded-full overflow-hidden " />
          <span className="inline-block">{questionContent}</span>
        </h4>
        <div className="row gy-2 gx-1">
          <div className="audit-options-container flex col-gap-20 mb-2 gap-y-4">
            {groupedAnswers.map((group, index) => (
              <MultiSelectOptions
                questionID={questionID}
                header={group.values.header}
                options={group.values.options}
                selectedOptions={selectedOptions}
                disabled
                className={`border-border border-[1px] ${
                  index === 0
                    ? "!border-green-400"
                    : index === 1
                      ? "!border-red-300"
                      : index === 2
                        ? "!border-border"
                        : ""
                }`}
              />
            ))}
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center">
              <label className="form-label font-weight-bold mb-1">
                Assessor Comment
              </label>
            </div>
            <p className="" dangerouslySetInnerHTML={{ __html: inspectorComment || "None" }}></p>
          </div>
          {requiresAction && (
            <div className="form-group mb-2">
              <CheckBox
                label="Requires Action"
                bold
                disabled
                checked={requiresAction}
              />
            </div>
          )}

          <ImageContainer>
            {imageAttachments.map((image) => (
              <ImageFull imageURL={image.imageURL} />
            ))}
          </ImageContainer>
        </div>
      </div>
    </div>
  );
}

export default HomeOwnerQuestion;

import React from "react";
export default function Footer() {
  return (
    <footer className="fs-container pb-8 pt-10 text-sm text-left">
      <p>
        Having issues with the platform? Please email{" "}
        <a className="underline" href="mailto:wmp.support@firesmartbc.ca">
          wmp.support@firesmartbc.ca
        </a>{" "}
        with a description of the issue and we will get back to you as soon as
        possible.
      </p>
    </footer>
  );
}
